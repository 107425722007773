import React from "react";

const FleboStatus = ({ data }) => {
  const getStatus = () => {
    if (
      data?.is_accepted === true &&
      data?.deposit_collecting_center === null &&
      data?.sample_collected === null &&
      data?.fablo_reached === null
    ) {
      return { text: "Phlebo Started", color: "text-green-600" };
    }
    if (data?.deposit_collecting_center === true) {
      if (data?.is_deposit_in_center_accepted === false) {
        return { text: "Deposit In Center Rejected", color: "text-red-600" };
      } else if (data?.is_deposit_in_center_accepted === true) {
        return { text: "Deposit In Center Accepted", color: "text-green-600" };
      }
      return { text: "Deposit In Center", color: "text-green-600" };
    }
    if (data?.sample_collected === true) {
      if (data?.is_sample_collected_accepted === false) {
        return { text: "Sample Collected Rejected", color: "text-red-600" };
      } else if (data?.is_sample_collected_accepted === true) {
        return { text: "Sample Collected Accepted", color: "text-yellow-600" };
      }
      return { text: "Sample Collected", color: "text-yellow-600" };
    }
    if (data?.fablo_reached === true) {
      return { text: "Reached", color: "text-blue-600" };
    }
    return { text: "Not Started Yet!", color: "text-red-600" };
  };

  const status = getStatus();

  return (
    <div className={`text-sm text-gray-900 ${status.color}`}>
      {status.text}
    </div>
  );
};

export default FleboStatus;
