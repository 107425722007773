import { axiosAuthorized } from "../../api/config";


export const getAppointments = async (id) => {
  try {
    const response = await axiosAuthorized.get(`/getlabbookinghospital/byfabloregion/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getHomeAppointments = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethomelabbooking`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getHospitalAppointments = async () => {
  try {
    const response = await axiosAuthorized.get(`/gethospitallabooking`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteAppointment = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labbookinghospital/delete_labbookinghospital/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateStatus = async (status, appointmetnId) => {
  try {
    const requestData = { ...status };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosAuthorized.put(`/labbookinghospital/update_labbookinghospital/${appointmetnId}`, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}
