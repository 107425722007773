import React from "react";
import { GrFormClose } from "react-icons/gr";
import TrackerBar from "./TrackerBar";

const AppointmentTrackerModal = ({
  setEditData,
  editData,
  setOpenTracker,
  setAppointmentsFn,
}) => {
  return (
    <div className="absolute flex w-full h-full top-0 left-0 z-10 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex w-full max-w-screen-md overflow-y-auto h-fit border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Track Appointment"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpenTracker(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <TrackerBar
          data={editData}
          setAppointmentsFn={setAppointmentsFn}
          setOpenTracker={setOpenTracker}
          setEditData={setEditData}
        />
      </div>
    </div>
  );
};

export default AppointmentTrackerModal;
