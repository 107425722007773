import React, {  useRef, useState } from 'react';
import { AddButton, ImageInput, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { addPackage } from '../../utils/packagesUtils/packagesUtils';
import JoditEditor, {Jodit} from "jodit-react";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { validatePackageForm } from '../../utils/packagesUtils/validatePackageForm';
import { useEffect } from 'react';
import { getVitalOrganCategories } from '../../utils/categoriesUtils/vitalOrganCategoryUtils';
import { getGeneralCategories } from '../../utils/categoriesUtils/generalCategoryUtils';
import { toast } from 'react-toastify';

const config = { readonly: false, placeholder: "Description",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  }, };
const PackageModal = ({ setOpen, editData = null, setEditData, setPackagesFn, user }) => {
  const initailsState = {
    health_package_name: editData?.health_package_name || "",
    health_package_mrp: editData?.health_package_mrp || 0,
    health_package_discount: editData?.health_package_discount || 0,
    general_category: editData?.general_category?.category_name || "Select General Category",
    vital_organ_category: editData?.vital_organ_category?.vital_category_name || "Select Vital Organ Category",
    sample: editData?.sample || "",
    age_group: editData?.age_group || "",
    gender: editData?.gender || "Select Gender"
  };
  const [test, setTests] = useState(editData?.test || []);
  const [testForm, handleTestForm, clearTestForm] = useForm({
    test_name:"",
  })
  const editor = useRef(null);
  const [description, setDescription] = useState(editData?.health_package_description || "<p></p>");
  const [testDescription, setTestDescription] = useState("<p></p>");
  const [package_image, setPackageImage] = useState(editData?.package_image || null);
  const [formData, handleFormData,clearForm] = useForm(initailsState);
  const [loading,setLoading] = useState(false);
  const [err, setErr] = useState({ global: "", test: "", faq: "" });
  const removeTest = (index) => {
      setTests(test.filter((_item, idx) => idx !== index));
  };
  const handleAddTest = () =>{
    if (!testForm.test_name.trim().length) {
      setErr({ test: "Invalid Test name" });
      return ;
    }

    if (!testDescription.replace(/<[^>]*>/g, "").trim().length) {
      setErr({ test: "Invalid Test Description" });
      return ;
    }
    setTests([...test,{test_name: testForm.test_name, test_description: testDescription}]);
    setTestDescription("<p></p>");
    clearTestForm();
  }
  const faqInitialState = { faq_question: "", faq_answer: "" };
  const [faqFormData, handleFaqFromData, clearFaqForm] =
    useForm(faqInitialState);
   const [faq, setFaqs] = useState(editData?.faq || []);
   const handleFaqs = (action, idx = 0) => {
     if (action === "add") {
       if (
         !faqFormData.faq_question.trim().length ||
         !faqFormData.faq_answer.trim().length
       ) {
         return setErr({ global: "Please Enter Question & Answer" });
       } else {
         setFaqs([
           ...faq,
           {
             faq_question: faqFormData.faq_question,
             faq_answer: faqFormData.faq_answer,
           },
         ]);
         clearFaqForm();
       }
     } else if (action === "remove") {
       setFaqs(faq.filter((_, index) => index !== idx));
     }
  };
  const [generalCategories, setGeneralCategories] = useState([]);
  const [generalCats, setGeneralCats] = useState([]);
  const [generalCatId, setGeneralCatId] = useState("");
  const [vitalOrganCategories, setVitalOrganCategories] = useState([]);
  const [vitalOrganCats, setVitalOrganCats] = useState([]);
  const [vitalOrganCatId, setVitalOrganCatId] = useState("");
  useEffect(() => {
    getGeneralCategories()
      .then((res) => {
        setGeneralCategories([
          { category_name: "Select General Category", _id: "" },
          ...res.data.data,
        ]);
        setGeneralCats([
          "Select General Category",
          ...res.data.data.map((item) => item.category_name),
        ]);
      })
      .catch((err) => {
        console.error(err);
        setGeneralCategories([
          { category_name: "Select General Category", _id: "" },
        ]);
      });
    getVitalOrganCategories()
      .then((res) => {
        setVitalOrganCategories([
          { vital_category_name: "Select Vital Organ Category", _id: "" },
          ...res.data.data,
        ]);
        setVitalOrganCats([
          "Select Vital Organ Category",
          ...res.data.data.map((item) => item.vital_category_name),
        ]);
      })
      .catch((err) => {
        console.error(err);
        setVitalOrganCategories([
          { vital_category_name: "Select Vital Organ Category", _id: "" },
        ]);
      });
  }, []);
  useEffect(() => {
    const foundCategory = generalCategories.find(
      (item) => item.category_name === formData.general_category
    );
    if (foundCategory) {
      setGeneralCatId(foundCategory._id);
    }
  }, [generalCategories, formData.general_category]);
  useEffect(() => {
    const foundCategory = vitalOrganCategories.find(
      (item) => item.vital_category_name === faqFormData.vital_organ_category
    );
    if (foundCategory) {
      setVitalOrganCatId(foundCategory._id);
    }
  }, [vitalOrganCategories, faqFormData.vital_organ_category]);
  const handleSubmit = (e) => {
      e.preventDefault();
      if (validatePackageForm(formData, description, test, package_image, setErr)) { 
          setLoading(true);
          addPackage(formData,description, generalCatId, vitalOrganCatId, test, package_image,faq,editData?._id)
            .then((res) => {
              setPackagesFn();
              setEditData(null);
              setPackageImage(null);
                clearForm();
                setDescription("<p></p>");
              setOpen(false);
              toast(`Package ${editData ? `Edited` : `Added`}`, {
            type: "success",
            autoClose: 3000,
          });
            })
            .catch((err) => {
              console.log(err); 
              toast(`Package ${editData ? `Edit Failed` : `Add Failed`}`, {
            type: "error",
            autoClose: 3000,
          });
            })
            .finally(() => setLoading(false));
      }
  }
  return (
    <div className="absolute flex w-full h-screen top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Package" : "Add New Package"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex flex-col md:flex-row w-full h-fit justify-between gap-5">
          <TextInput
            name="Package Name"
            formName={"health_package_name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            data={formData}
            handleInput={handleFormData}
            items={generalCats}
            name={"General Category"}
            formName={"category"}
            setError={setErr}
          />
          <SelectInput
            data={formData}
            handleInput={handleFormData}
            items={vitalOrganCats}
            name={"Vital Organ Category"}
            formName={"vital_organ_category"}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput
            name="Gender"
            formName={"gender"}
            data={formData}
            handleInput={handleFormData}
            items={["Select Gender", "Male", "Female", "Both"]}
            setError={setErr}
          />
          <NumberInput
            name="MRP"
            formName={"health_package_mrp"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            name="Discount"
            formName={"health_package_discount"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            hide={true}
            name="Sample"
            formName={"sample"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            name="Age Group"
            formName={"age_group"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) => {
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              );
            }}
          />
        </div>
        {test?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {test?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-white text-xs"
                key={idx}
              >
                <span>{item?.test_name}</span>
                <button
                  onClick={() => {
                    removeTest(idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col w-full h-fit gap-5">
          <TextInput
            name={"Test Name"}
            formName={"test_name"}
            data={testForm}
            handleInput={handleTestForm}
            setError={setErr}
          />
          <div className="flex flex-col w-full gap-0.5">
            <label htmlFor={"Test Description"} className="label">
              {"Test Description"} <span className="text-red-600">*</span>
            </label>
            <JoditEditor
              ref={editor}
              value={testDescription}
              config={config}
              tabIndex={1}
              onBlur={(newContent) => {
                setTestDescription(
                  newContent.replace(
                    /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                    '<td style="border: 1px solid black;" >'
                  )
                );
              }}
            />
          </div>
          <div className="flex bg-slate-200 rounded-md w-fit h-fit">
            <button
              className="p-1 border rounded-md -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs"
              onClick={() => {
                handleAddTest();
              }}
            >
              Add
            </button>
          </div>
        </div>
        {err.test && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.test}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={package_image} setImage={setPackageImage} />
          <ImageInput imageName={"Package Image"} setImage={setPackageImage} />
        </div>
        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-white text-xs"
                key={idx}
              >
                <span>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col w-full gap-5">
          <TextInput
            hide={true}
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setErr}
          />
          <div className="flex">
            <TextAreaInput
              data={faqFormData}
              handleInput={handleFaqFromData}
              name={"Answer"}
              formName={"faq_answer"}
              setError={setErr}
            />
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageModal;