export const validateLoginForm = (initialState, setErr) => {
    const passwordPattern = /^.{8,}$/

    if (!initialState.fablo_id.trim()) {
        setErr({ global: "Invalid Felbo Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}