import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../context/useAuth";
import { getAppointments } from "../../utils/appointmentUtils/appointmentUtils";
import Appointments from "./Appointments";
import AppointmentTrackerModal from "./AppointmentTrackerModal/AppointmentTrackerModal";
const AppointmentsMainSection = () => {
  const user = useAuth().authState.user;
  const [appointments, setAppointments] = useState([]);
  const setAppointmentsFn = useCallback(() => {
    getAppointments(user?._id).then(res => setAppointments(res.data.data)).catch(err => console.error(err));
  },[user?._id]);
  useEffect(() => {
    setAppointmentsFn();
  }, [setAppointmentsFn]);
  const [openTracker, setOpenTracker] = useState(false);
  const [editData, setEditData] = useState(null);
  return (
    <>
      {openTracker && (
        <AppointmentTrackerModal
          setEditData={setEditData}
          editData={editData}
          setOpenTracker={setOpenTracker}
          setAppointmentsFn={setAppointmentsFn}
        />
      )}
      <div className="flex flex-col w-full h-full">
        <Appointments
          appointments={appointments}
          setAppointmentsFn={setAppointmentsFn}
          setEditData={setEditData}
          setOpenTracker={setOpenTracker}
        />
      </div>
    </>
  );
};

export default AppointmentsMainSection;
